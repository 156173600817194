<template>
    <div class="baseMain">
        <div class="baseSearch clearfloat">
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>模板名称：</span>
                </div>
                <div class="baseSearchOneRight">
                    <el-input v-model="searchForm.form_name" placeholder="请输入内容" clearable></el-input>
                </div>
            </div>
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>模板状态：</span>
                </div>
                <div class="baseSearchOneRight">
                    <el-select v-model="searchForm.status" placeholder="请选择" clearable @change="search">
                        <el-option label="禁用" value="0"></el-option>
                        <el-option label="正常" value="1"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="baseSearchOne">
                <div class="baseSearchOneLeft">
                    <span>是否模板：</span>
                </div>
                <div class="baseSearchOneRight">
                    <el-select v-model="searchForm.is_tmpl" placeholder="请选择" clearable @change="search">
                        <el-option label="否" value="0"></el-option>
                        <el-option label="是" value="1"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="baseSearchOne">
                <el-button type="primary" style="margin-left: 20px;" @click="search">搜索</el-button>
                <el-button @click="cleanAll">清空</el-button>
            </div>
        </div>
        <div class="baseOperate clearfloat">
            <div class="baseOperateOne">
                <el-button type="primary" @click="openAddPlan('new')" v-if="authorityStatus.new.show">创建模板</el-button>
            </div>
        </div>
        <div class="baseTable">
            <publicTable :publicTableOtherHeight="searchHeight" :publicTableData="tableData">
                <el-table-column prop="id" label="模板ID"></el-table-column>
                <el-table-column prop="form_name" label="模板名称"></el-table-column>
                <el-table-column prop="cate" label="分类">
                    <template #default="scope">
                        <span>{{ cateFilter(scope.row.cate) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="is_tmpl" label="是否模板">
                    <template #default="scope">
                        <span v-if="scope.row.is_tmpl == 0">否</span>
                        <span v-else-if="scope.row.is_tmpl == 1">是</span>
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="状态">
                    <template #default="scope">
                        <el-tag type="danger" v-if="scope.row.status == 0">禁用</el-tag>
                        <el-tag type="success" v-else-if="scope.row.status == 1">正常</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template #default="scope">
                        <el-link class="tableButton" type="primary"
                                 @click.prevent="openAddPlan('edit',scope.row)">
                            编辑
                        </el-link>
                        <el-link class="tableButton" type="success"
                                 @click.prevent="toCustomForm(scope.row)">
                            配置
                        </el-link>
                        <el-link class="tableButton" type="danger" @click.prevent="openDel(scope.row)">
                            删除
                        </el-link>
                    </template>
                </el-table-column>
            </publicTable>
        </div>
        <div class="basePage" style="margin-top: 4px;">
            <publicPage ref="publicPageUserStudent" :publicPageTotal="{total:total}"
                        @pageChange="getTableData"></publicPage>
        </div>
        <el-dialog title="提示" v-model="dialogVisible" width="800px" :fullscreen="true">
            <template #footer>
                <div class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog :title="drawerTitle" v-model="drawer" width="600px">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px">
                <el-form-item label="模板名称" prop="form_name">
                    <el-input v-model="ruleForm.form_name" style="width: 300px;"></el-input>
                </el-form-item>
                <el-form-item label="是否模板">
                    <el-radio-group v-model="ruleForm.is_tmpl">
                        <el-radio :label="0">否</el-radio>
                        <el-radio :label="1">是</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="模板状态">
                    <el-radio-group v-model="ruleForm.status">
                        <el-radio :label="0">禁用</el-radio>
                        <el-radio :label="1">正常</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="排序">
                    <el-input-number v-model="ruleForm.sort" :min="0" controls-position="right" style="width: 300px;" />
                </el-form-item>
                <el-form-item label="分类" prop="cate">
                    <el-select v-model="ruleForm.cate" placeholder="请选择" clearable style="width: 300px;">
                        <el-option
                                v-for="item in cateList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="drawer = false">取消</el-button>
                    <el-button type="primary" @click="saveAddPlan">
                        确认
                    </el-button>
                </span>
            </template>
        </el-dialog>
        <el-dialog title="全部报名流程" v-model="classListDialog" width="600px" :close-on-click-modal="false">
            <el-tag v-for="item in classListMsg" :key="item.recruit_id" style="margin: 8px;">
                {{ item.batch_name }}
            </el-tag>
        </el-dialog>
    </div>
</template>

<script>

    import publicPage from '../../components/publicPage.vue'

    import publicTable from '../../components/publicTable.vue'

    import {businessGet, businessPost, businessPut, businessDel} from '../../api/business.js'

    import {utilsGetAuthority} from '../../utils/utils.js'

    export default {
        name: "formTmpl",
        components: {
            publicPage,
            publicTable,
        },
        data() {
            return {
                searchForm: {
                    form_name: '',
                    status: '',
                    is_tmpl: '',
                },
                input: '',
                searchHeight: 300,
                tableData: [],
                cateList: [],
                total: 0,
                selMsg: null,
                dialogVisible: false,
                drawerTitle: '',
                drawer: false,
                abc: '',
                ruleForm: {
                    form_name: '',
                    is_tmpl: 0,
                    status: 0,
                    sort: 0,
                    cate: ''
                },
                rules: {
                    form_name: [
                        {required: true, message: '该项不能为空', trigger: 'blur'},
                    ],
                    cate: [
                        {required: true, message: '该项不能为空', trigger: 'blur'},
                    ],
                },
                authorityStatus: {
                    new: {
                        method: 'post',
                        url: '/admin/v1/form/store',
                        show: false,
                    },
                    edit: {
                        method: 'put',
                        url: '/admin/v1/form/update/{id}',
                        show: false,
                    },
                    del: {
                        method: 'delete',
                        url: '/admin/v1/form/destroy/{id}',
                        show: false,
                    },
                },
                classListMsg: [],
                classListDialog: false
            }
        },
        mounted() {
            this.getSortList()
            utilsGetAuthority(this.authorityStatus)
            this.$nextTick(() => {
                let baseSearchDom = document.querySelector('.baseSearch');
                let baseOperateDom = document.querySelector('.baseOperate');
                let searchHeight = baseSearchDom.offsetHeight + baseOperateDom.offsetHeight;
                this.searchHeight = searchHeight;
                this.cleanAll();
            })
        },
        methods: {
            toCustomForm(val){
                this.$confirm('是否跳转到自定义表单？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    this.$router.push('/signupCustomForm' + '?form_id=' + val.id)
                }).catch(() => {})
            },
            cateFilter(val) {
                let str = ''
                this.cateList.forEach(item => {
                    if(item.value == val) {
                        str = item.label
                    }
                })
                return str
            },
            downBaoming(item) {
                window.open(item.qrcode);
            },
            openAllClass(val) {
                this.classListMsg = val.sign_up;
                this.classListDialog = true;
            },
            openDel(val) {
                this.$confirm('从操作将删除该模板，是否继续？', '提示', {
                    confirmButtonText: '确认',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    let url = '/admin/v1/form/destroy/' + val.id;
                    businessDel(url, {}).then(res => {
                        if (res.data.status == 200) {
                            this.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'success'
                            });
                            this.getTableData();
                        }
                        else {
                            this.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'warning'
                            });
                        }
                    })
                }).catch(() => {

                })
            },
            saveAddPlan() {
                let that = this;
                this.$refs.ruleForm.validate((valid) => {
                    if (valid) {
                        msgSubmit()
                    }
                })

                function msgSubmit() {
                    let data = {
                        form_name: that.ruleForm.form_name,
                        is_tmpl: that.ruleForm.is_tmpl,
                        status: that.ruleForm.status,
                        sort: that.ruleForm.sort,
                        cate: that.ruleForm.cate,
                    }
                    if (that.drawerTitle == '新建') {
                        let url = '/admin/v1/form/store';
                        businessPost(url, data).then(res => {
                            if (res.data.status == 200) {
                                that.$message({
                                    showClose: true,
                                    message: res.data.message,
                                    type: 'success'
                                });
                                that.drawer = false;
                                that.getTableData();
                            }
                            else {
                                that.$message({
                                    showClose: true,
                                    message: res.data.message,
                                    type: 'warning'
                                });
                            }
                        })
                    }
                    else if (that.drawerTitle == '编辑') {
                        let url = '/admin/v1/form/update/' + that.selMsg.id;
                        data = Object.assign(data, {id: that.selMsg.id})
                        businessPut(url, data).then(res => {
                            if (res.data.status == 200) {
                                that.$message({
                                    showClose: true,
                                    message: res.data.message,
                                    type: 'success'
                                });
                                that.drawer = false;
                                that.getTableData();
                            }
                            else {
                                that.$message({
                                    showClose: true,
                                    message: res.data.message,
                                    type: 'warning'
                                });
                            }
                        })
                    }
                }
            },
            openAddPlan(sort, val) {
                let that = this
                if (sort == 'new') {
                    that.drawerTitle = '新建'
                    that.ruleForm.form_name = '';
                    that.ruleForm.is_tmpl = 0;
                    that.ruleForm.status = 0;
                    that.ruleForm.sort = 0;
                    that.ruleForm.cate = '';
                    that.drawer = true;
                }
                else if (sort == 'edit') {
                    let url = '/admin/v1/form/info?id=' + val.id
                    businessGet(url).then(res => {
                        if (res.status == 200) {
                            that.drawerTitle = '编辑'
                            that.selMsg = res.data.data;
                            that.ruleForm.id = that.selMsg.id;
                            that.ruleForm.form_name = that.selMsg.form_name;
                            that.ruleForm.is_tmpl = that.selMsg.is_tmpl;
                            that.ruleForm.status = that.selMsg.status;
                            that.ruleForm.sort = that.selMsg.sort;
                            that.ruleForm.cate = that.selMsg.cate;
                            if(that.ruleForm.id) that.drawer = true;
                        }
                        else {
                            that.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'warning'
                            });
                        }
                    })
                }
            },
            cleanAll() {
                this.searchForm.form_name = '';
                this.searchForm.is_tmpl = '';
                this.searchForm.status = '';
                this.search();
            },
            search() {
                this.$refs.publicPageUserStudent.currentPage = 1;
                this.getTableData();
            },
            getTableData() {
                let url = '/admin/v1/form/index?page=' + this.$refs.publicPageUserStudent.currentPage + '&page_pre=' + this.$refs.publicPageUserStudent.pageSize;
                for (let k in this.searchForm) {
                    if (this.searchForm[k]) {
                        url = url + '&' + k + '=' + this.searchForm[k];
                    }
                }
                this.tableLoading = true;
                businessGet(url).then(res => {
                    this.tableLoading = false;
                    if (res.data.status == 200) {
                        this.total = res.data.data.count;
                        this.tableData = res.data.data.list;
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            },
            getSortList() {
                let url = '/admin/v1/category';
                businessGet(url).then(res => {
                    if (res.data.status == 200) {
                        let cateList = [];
                        res.data.data.forEach(item => {
                            let a = {
                                value: item.id + '',
                                label: item.name
                            }
                            cateList.push(a)
                        })
                        this.cateList = cateList;
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            }
        }
    }
</script>

<style scoped>

    @import "../../assets/css/base.css";

    .container {
        width: 100%;
        /*height: 600px;*/
        box-sizing: border-box;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }

    .signupProcessDrawerForm {
        width: 800px;
        margin: 20px auto;
    }

    .signupPSRightButtonOne {
        margin: 0 6px;
    }

</style>
